// log
import store from "../store";

const fetchDataBase2Request = () => {
  return {
    type: "CHECK_DATABASE2_REQUEST",
  };
};

const fetchDataBase2Success = (payload) => {
  return {
    type: "CHECK_DATABASE2_SUCCESS",
    payload: payload,
  };
};

const fetchDataBase2Failed = (payload) => {
  return {
    type: "CHECK_DATABASE2_FAILED",
    payload: payload,
  };
};

export const fetchDataBase2 = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataBase2Request());
    try {
      let imageURI = [];
      let tokenId = [];
      let walletOfOwner = await store
        .getState()
        .blockchain.smartContract2.methods.walletOfOwner(account)
        .call();
      tokenId = walletOfOwner;
      let timId;
      // Dateオブジェクトを作成
      var date = new Date();
      // UNIXタイムスタンプを取得する (ミリ秒単位)
      var nowUnixJs = date.getTime();
      // UNIXタイムスタンプを取得する (秒単位 - PHPのtime()と同じ)
      var nowUnix = Math.floor(nowUnixJs / 1000);
      let totalPoint = 0;
      let point = 0;
      for (let i = 0; i < walletOfOwner.length; i++) {
        timId = await store
          .getState()
          .blockchain.smartContract2.methods.getTokenTim(tokenId[i])
          .call();
        point = Math.floor((nowUnix - timId) / 86400);
        totalPoint += point;
        imageURI[tokenId[i]] = [
          i,
          // "https://startdata.io/NSK/" + String(tokenId[i]) + ".png?r=1",
          "https://startdata.io/CNW/" + String(tokenId[i]) + ".png?r=1",
          "#" + String(tokenId[i]),
          tokenId[i],
          point,
        ];
      }

      // let cost = await store
      //   .getState()
      //   .blockchain.smartContract.methods.cost()
      //   .call();

      dispatch(
        fetchDataBase2Success({
          imageURI,
          totalPoint,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataBase2Failed("Could not load data from contract."));
    }
  };
};

const initialState = {
  loading: false,
  balanceOf: false,
  maxMintsPerWL: 1,
  maxMintsPerPS: 2,
  currentIndex: 10650,
  error: false,
  errorMsg: "",
};

const dataSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATASUB_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_SUCCESS":
      return {
        ...state,
        loading: false,
        balanceOf: action.payload.balanceOf,
        maxMintsPerWL: action.payload.maxMintsPerWL,
        maxMintsPerPS: action.payload.maxMintsPerPS,
        currentIndex: action.payload.currentIndex,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataSubReducer;

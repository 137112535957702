import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import blockchainReducer from "./blockchain/blockchainReducer";
import dataReducer from "./data/dataReducer";
import dataSubReducer from "./datasub/datasubReducer";
import dataBaseReducer from "./database/databaseReducer";
import dataBase2Reducer from "./database2/database2Reducer";
import dataBase3Reducer from "./database3/database3Reducer";

const rootReducer = combineReducers({
  blockchain: blockchainReducer,
  data: dataReducer,
  datasub: dataSubReducer,
  database: dataBaseReducer,
  database2: dataBase2Reducer,
  database3: dataBase3Reducer,
});

const middleware = [thunk];
const composeEnhancers = compose(applyMiddleware(...middleware));

const configureStore = () => {
  return createStore(rootReducer, composeEnhancers);
};

const store = configureStore();

export default store;

const initialState = {
  loading: false,
  imageURI: [],
  unload: true,
  error: false,
  totalPoint: false,
  errorMsg: "",
};

const dataBase3Reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATABASE3_REQUEST":
      return {
        ...state,
        loading: true,
        unload: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABASE3_SUCCESS":
      return {
        ...state,
        loading: false,
        // tokenURI: action.payload.tokenURI,
        imageURI: action.payload.imageURI,
        totalPoint: action.payload.totalPoint,
        unload: false,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATABASE3_FAILED":
      return {
        ...initialState,
        loading: false,
        unload: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataBase3Reducer;

import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
// import { fetchData } from "./redux/data/dataActions";
// import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataBase2 } from "./redux/database2/database2Actions";
import { fetchDataBase3 } from "./redux/database3/database3Actions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import LazyAndPreloadImage from "./components/LazyAndPreloadImage";
import Box from "@mui/material/Box";
import { Grid } from "@material-ui/core";
import MuiPagination from "@material-ui/lab/Pagination";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  color: #fff;
  font-size: 2rem;
  font-family: DelaGothicOne;
  border-radius: 10px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #ff0000;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-top: transparent;
    border-right: transparent;
    transform: rotate(-135deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1.6rem;
    margin: auto;
  }
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  position: relative;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledIncDecButton = styled.button`
  padding: 10px 18px !important;
  border-radius: 2px;
  margin: 0 2px;
  border: solid 1px #333;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
  color: #333;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const BadgeBox = styled.div`
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 0.3rem;
  /* margin-left: 2rem; */
  border-radius: 0.6rem;
  font-size: 1rem;
  /* text-transform: uppercase; */
  text-align: center;
  white-space: nowrap;
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: auto;
  @media (min-width: 767px) {
    width: 500px;
    height: auto;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 50%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

export const BoxList = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 24px;
  border-radius: 24px;
  border: 4px solid #333;
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TextDescription = styled.p`
  color: gray;
  font-size: 12px;
  padding: 1rem;
  line-height: 1.6;
`;

export const StyledTitles = styled.img`
  width: 100%;
  position: relative;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const StyledTitlesCover = styled.img`
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
`;

export const StyledDiverTitle = styled.img`
  width: 1.4rem;
  /* @media (min-width: 767px) {
    width: 300px;
  } */
  transform: rotateY(45deg);
  animation: rotate-anime-3d 3s linear infinite;
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const TitlesTitle = styled.p`
  color: var(--accent-text);
  font-size: 30px;
  font-weight: 500;
  line-height: 1.6;
`;

export const StyledTitlesName = styled.p`
  /* background-color: var(--accent-text); */
  background-color: rgba(255, 255, 255, 0.3);
  font-size: 24px 0;
  text-align: center;
  text-align: "center";
  font-weight: "bold";
  color: deepskyblue;
  line-height: 2;
`;

export const StyledButtonWall = styled.button`
  color: #fff;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  padding: 1rem 0;
  margin: 0 0 1rem 0;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow);
  background-size: 700% 700%;
  animation: gradation 1.7s ease-in infinite;
  :hover {
    cursor: pointer;
  }
`;
function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const database2 = useSelector((state) => state.database2);
  const database3 = useSelector((state) => state.database3);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [mintAmount, setMintAmount] = useState(1);
  const [minted, setminted] = useState(0);
  const [numPage, setnumPage] = useState(20);
  const [mintedWl, setmintedWl] = useState(0);
  const [canFreeMint, setcanFreeMint] = useState(0);
  const [mintable, setMintable] = useState(0);
  const [whitelistProof, setwhitelistProof] = useState("");
  const [whitelistValid, setwhitelistValid] = useState(false);
  const [publistProof, setpublistProof] = useState("");
  const [publistValid, setpublistValid] = useState(false);
  const [publist2Valid, setpublist2Valid] = useState(false);
  //ページ番号
  const [page, setPage] = useState(1);

  const Pagination = withStyles({
    root: {
      display: "inline-block", //中央寄せのためインラインブロックに変更
      backgroundColor: "rgba(255,255,255,0.5)",
    },
  })(MuiPagination);

  const SelectRaw = withStyles({
    root: {
      display: "inline-block", //中央寄せのためインラインブロックに変更
      backgroundColor: "rgba(255,255,255,0.5)",
    },
  })(Select);

  const [lang, setlang] = useState(false);
  const [langData, setlangData] = useState({
    fail: "Failed,Please try again.",
    chkops: "you minted STARTJPN Ticket! check on your Opensea.",
    minting: "Minting ...",
    salestartwl: "Free Mint：12/26(Mon)PM8:00〜(JST)",
    salestartwl2: "",
    salestartpb: "Public Sale：3/2(Thu)PM9:00〜(JST)",
    soldout: "Sold Out! thank you!",
    wl: "Presale",
    pb: "Public Sale",
    gasfee: "+gas Fee",
    network: "to get STK,Please Connect to Ethereum Mainnet.",
    appAlert:
      "You cannot reapply for an application that has already been submitted.",
    busy: "Busy",
    minted: "minted",
    mintwl: "mintWL",
    mintps: "mintPS",
    youhavewl: "You are on the Whitelist!",
    thanksMinted: "Thank you for minting.",
    thanksMintedWl: "Thank you for minting AL.",
    pbnotStarted: "Public sale has not started",
    youhavenotwl: "You are not Whitelisted.",
    ntwalrt: "changed network.please try to connect.",
    tryconn: "Please try Connect.",
    reload: "reload",
    application: "application",
    applied: "applied",
    cancel: "cancel",
    displaymsg: "CNWs held are displayed.",
    hodltitle: "Your CNWs(You have ",
    displaymsgnsk: "NSKs held are displayed.",
    displaymsgcnw: "CNWs held are displayed.",
    displaymsgpnl: "PNLs held are displayed.",
    hodltitlensk: "Your NSKs",
    hodltitlecnw: "Your CNWs",
    hodltitlepnl: "Your PNLs",
    tickets: " Tickets.)",
    ticketBurned: "1 ticket will be burned.",
    youhavepb: "you whitelisted to public Sale.",
    youhavepb2: "you whitelisted to 2nd public Sale.",
    ticketNotReturn:
      "you do not get your ticket back, but do you want to run it?",
    canceled: "The process was not executed.",
    comingsoon: "Coming soon...",
    totalApplication: "Total.App:",
    evos: "EVOs.",
  });
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 5000,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    MARKETPLACE_BASEURI: "https://opensea.io/collection/neostackey/",
    SHOW_BACKGROUND: false,
  });

  const changeLang = () => {
    setlang(!lang);
    let langJson = require("./data/jp.json");
    if (lang) {
      langJson = require("./data/en.json");
    }
    setlangData(langJson);
    getInit();
  };

  const getData = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getDataSub = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataSub(blockchain.account));
    }
  };
  const getDataBase = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase(blockchain.account));
    }
  };
  const getDataBase2 = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase2(blockchain.account));
    }
  };
  const setNumPageAndMore = (e) => {
    setnumPage(e.target.value);
    setPage(1);
  };

  const getDataBase3 = () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchDataBase3(blockchain.account));
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    } else {
      // getDataSub();
      getDataBase();
      getDataBase2();
      getDataBase3();
    }
    // getData();
    // getWhitelist();
    // getPblist();
    // getPblist2();
    // checkMinted();
    // checkMintedwl();
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };

  async function intervalGet() {
    const intervalId = setInterval(() => {
      getData();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }
  useEffect(() => {
    getConfig();
    // checkMinted();
    // checkMintedwl();
    getInit();
    // intervalGet();
  }, []);

  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  useEffect(() => {
    if (claimingNft == false) {
      getInit();
    }
  }, [claimingNft]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "rgba(0, 0, 0,0)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <ul className="bgtrans">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <StyledLogo alt={"logo"} src={"/startlogo.png"} />
        {/* <StyledLogo alt={"logo"} src={null} /> */}
        <ResponsiveWrapper
          flex={1}
          style={{ padding: 24, background: "" }}
          test
        >
          {/* {isMobile ? (<></>) : (<>

            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <CircleTextLogo />
          </s.Container> */}

          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "rgba(51,51,51,0.7)",
              padding: 24,
              borderRadius: 24,
              // border: "4px dashed var(--secondary)",
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              LANG/言語
            </s.TextTitle>
            <div class="toggle-switch">
              <input
                id="toggleBox"
                class="toggle-input"
                type="checkbox"
                style={{ display: "none" }}
                onChange={(e) => {
                  // e.preventDefault();
                  changeLang();
                }}
              />
              <label for="toggleBox" class="toggle-label" />
            </div>

            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--accent-text)",
              }}
            >
              STARTJPN Point
            </s.TextDescription>

            <s.SpacerSmall />

            <>
              <s.SpacerSmall />
              {blockchain.account === "" ||
              blockchain.smartContract === null ||
              blockchain.account === undefined ? (
                <s.Container ai={"center"} jc={"center"}>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    Connect to the {CONFIG.NETWORK.NAME} network
                  </s.TextDescription>
                  <s.SpacerSmall />
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(connect());
                      getInit();
                    }}
                  >
                    CONNECT
                  </StyledButton>
                  {blockchain.errorMsg !== "" ? (
                    <>
                      <s.SpacerSmall />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {blockchain.errorMsg}
                      </s.TextDescription>
                    </>
                  ) : null}
                </s.Container>
              ) : (
                <>
                  <s.TextTitle style={{ textAlign: "center", color: "white" }}>
                    {"Total:"}
                    {database.totalPoint +
                      database2.totalPoint +
                      database3.totalPoint}
                    {"Pt."}
                  </s.TextTitle>
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    {feedback}
                  </s.TextDescription>
                </>
              )}
            </>
            <s.SpacerMedium />

            <s.SpacerLarge />

            <s.Container ai={"center"} jc={"center"} fd={"row"}>
              <StyledButtonReload
                onClick={(e) => {
                  e.preventDefault();
                  getReload();
                }}
              >
                {claimingNft ? langData.busy : "RELOAD"}
              </StyledButtonReload>
            </s.Container>
          </s.Container>
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg
              alt={"example"}
              src={"/config/images/circle.gif"}
              style={{ transform: "scaleX(-1)" }}
            />
          </s.Container> */}
          {/* {isMobile ? (<></>) : (<>
            <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/logo.png"} />
          </s.Container>
          </>)} */}
          {/* <s.Container flex={1} jc={"center"} ai={"center"}>
            {isMobile ? (
              <></>
            ) : (
              <>
                <CircleTextLogo />
              </>
            )}
          </s.Container> */}
        </ResponsiveWrapper>
        <s.SpacerMedium />
        {/* NSK保有 */}
        <s.SpacerLarge />

        <BoxList>
          <Grid container direction="column">
            <Grid item container>
              <Grid xs={12} sm={12}>
                {database.unload ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.displaymsgnsk}
                    </TitlesTitle>
                  </>
                ) : (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.hodltitlensk}
                      {"/"}
                      {database.totalPoint}
                      {"Pt."}
                    </TitlesTitle>
                    <div>
                      {/* Select の基本 */}
                      <SelectRaw
                        onChange={(e) => setNumPageAndMore(e)} //変更されたときに走る関数。第2引数にページ番号が入る
                        style={{
                          textAlign: "center",
                          backgroundColor: "rgba(255,255,255,0.5)",
                          perspective: "500px",
                          color: "primary.main",
                        }}
                      >
                        <MenuItem value={5} disabled={5 == numPage}>
                          5件(raw)
                        </MenuItem>
                        <MenuItem value={20} disabled={20 == numPage}>
                          20件(raw)
                        </MenuItem>
                        <MenuItem value={50} disabled={50 == numPage}>
                          50件(raw)
                        </MenuItem>
                        <MenuItem value={80} disabled={80 == numPage}>
                          80件(raw)
                        </MenuItem>
                        <MenuItem value={100} disabled={100 == numPage}>
                          100件(raw)
                        </MenuItem>
                        <MenuItem value={150} disabled={150 == numPage}>
                          150件(raw)
                        </MenuItem>
                        <MenuItem value={200} disabled={200 == numPage}>
                          200件(raw)
                        </MenuItem>
                      </SelectRaw>
                    </div>
                    {database.imageURI.length > 0 ? (
                      <>
                        <div style={{ textAlign: "center" }}>
                          <Pagination
                            count={Math.ceil(
                              (database.imageURI.slice(-1)[0][0] + 1) / numPage
                            )} //総ページ数
                            color="secondary" //ページネーションの色
                            shape="rounded"
                            onChange={(e, page) => setPage(page)} //変更されたときに走る関数。第2引数にページ番号が入る
                            page={page} //現在のページ番号
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <s.SpacerLarge />
                  </>
                )}

                {database.loading ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {"Loading..."}
                    </TitlesTitle>
                  </>
                ) : (
                  <></>
                )}

                <Grid xs={12} sm={12}>
                  <Box
                    sx={{
                      width: 1 / 1.25,
                      height: 1 / 1,
                      mx: "auto",
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  ></Box>
                </Grid>
              </Grid>
              {database.imageURI != undefined
                ? database.imageURI?.map((imageURI) => (
                    <>
                      {(imageURI[0] + 1) / numPage > page - 1 &&
                      (imageURI[0] + 1) / numPage <= page ? (
                        <>
                          {" "}
                          <Suspense fallback={<></>}>
                            <Grid
                              xs={6}
                              sm={2}
                              key={imageURI[0]}
                              style={{
                                perspective: "500px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 1 / 1.25,
                                  height: 1 / 1,
                                  mx: "auto",
                                  display: "block",
                                  backgroundColor: "none",
                                  transformStyle: "preserve-3d",
                                  animation: "fadein 1s linear forwards",
                                  opacity: "0",
                                  // animationDelay: imageURI[0] + "s",
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                              >
                                <LazyAndPreloadImage
                                  alt={"logo" + imageURI[0]}
                                  src={imageURI[1]}
                                  befsrc={"/config/images/hidden.png"}
                                />

                                <StyledLink
                                  target={"_blank"}
                                  href={
                                    CONFIG.MARKETPLACE_BASEURI +
                                    CONFIG.CONTRACT_ADDRESS_NSK +
                                    "/" +
                                    imageURI[3]
                                  }
                                >
                                  <StyledTitlesName>
                                    {imageURI[2]}
                                    <BadgeBox>
                                      {imageURI[4]}
                                      {"Pt"}
                                    </BadgeBox>
                                  </StyledTitlesName>
                                </StyledLink>
                              </Box>
                            </Grid>
                          </Suspense>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                : ""}
            </Grid>
          </Grid>
          <s.SpacerLarge />
        </BoxList>
        {/* NSK保有ここまで */}

        {/* CNW保有 */}
        <s.SpacerLarge />
        <BoxList>
          <Grid container direction="column">
            <Grid item container>
              <Grid xs={12} sm={12}>
                {database2.unload ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.displaymsgcnw}
                    </TitlesTitle>
                  </>
                ) : (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.hodltitlecnw}
                      {"/"}
                      {database2.totalPoint}
                      {"Pt."}
                    </TitlesTitle>
                    <div>
                      {/* Select の基本 */}
                      <SelectRaw
                        onChange={(e) => setNumPageAndMore(e)} //変更されたときに走る関数。第2引数にページ番号が入る
                        style={{
                          textAlign: "center",
                          backgroundColor: "rgba(255,255,255,0.5)",
                          perspective: "500px",
                          color: "primary.main",
                        }}
                      >
                        <MenuItem value={5} disabled={5 == numPage}>
                          5件(raw)
                        </MenuItem>
                        <MenuItem value={20} disabled={20 == numPage}>
                          20件(raw)
                        </MenuItem>
                        <MenuItem value={50} disabled={50 == numPage}>
                          50件(raw)
                        </MenuItem>
                        <MenuItem value={80} disabled={80 == numPage}>
                          80件(raw)
                        </MenuItem>
                        <MenuItem value={100} disabled={100 == numPage}>
                          100件(raw)
                        </MenuItem>
                        <MenuItem value={150} disabled={150 == numPage}>
                          150件(raw)
                        </MenuItem>
                        <MenuItem value={200} disabled={200 == numPage}>
                          200件(raw)
                        </MenuItem>
                      </SelectRaw>
                    </div>

                    {database2.imageURI.length > 0 ? (
                      <>
                        <div style={{ textAlign: "center" }}>
                          <Pagination
                            count={Math.ceil(
                              (database2.imageURI.slice(-1)[0][0] + 1) / numPage
                            )} //総ページ数
                            color="secondary" //ページネーションの色
                            shape="rounded"
                            onChange={(e, page) => setPage(page)} //変更されたときに走る関数。第2引数にページ番号が入る
                            page={page} //現在のページ番号
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <s.SpacerLarge />
                  </>
                )}

                {database2.loading ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {"Loading..."}
                    </TitlesTitle>
                  </>
                ) : (
                  <></>
                )}

                <Grid xs={12} sm={12}>
                  <Box
                    sx={{
                      width: 1 / 1.25,
                      height: 1 / 1,
                      mx: "auto",
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  ></Box>
                </Grid>
              </Grid>
              {database2.imageURI != undefined
                ? database2.imageURI?.map((imageURI) => (
                    <>
                      {(imageURI[0] + 1) / numPage > page - 1 &&
                      (imageURI[0] + 1) / numPage <= page ? (
                        <>
                          {" "}
                          <Suspense fallback={<></>}>
                            <Grid
                              xs={6}
                              sm={2}
                              key={imageURI[0]}
                              style={{
                                perspective: "500px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 1 / 1.25,
                                  height: 1 / 1,
                                  mx: "auto",
                                  display: "block",
                                  backgroundColor: "none",
                                  transformStyle: "preserve-3d",
                                  animation: "fadein 1s linear forwards",
                                  opacity: "0",
                                  // animationDelay: imageURI[0] + "s",
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                              >
                                <LazyAndPreloadImage
                                  alt={"logo" + imageURI[0]}
                                  src={imageURI[1]}
                                  befsrc={"/config/images/hiddencnw.png"}
                                />

                                <StyledLink
                                  target={"_blank"}
                                  href={
                                    CONFIG.MARKETPLACE_BASEURI +
                                    CONFIG.CONTRACT_ADDRESS_CNW +
                                    "/" +
                                    imageURI[3]
                                  }
                                >
                                  <StyledTitlesName>
                                    {imageURI[2]}
                                    <BadgeBox>
                                      {imageURI[4]}
                                      {"Pt"}
                                    </BadgeBox>
                                  </StyledTitlesName>
                                </StyledLink>
                              </Box>
                            </Grid>
                          </Suspense>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                : ""}
            </Grid>
          </Grid>
          <s.SpacerLarge />
        </BoxList>
        {/* CNW保有ここまで */}

        {/* PNL保有 */}
        <s.SpacerLarge />
        <BoxList>
          <Grid container direction="column">
            <Grid item container>
              <Grid xs={12} sm={12}>
                {database3.unload ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.displaymsgpnl}
                    </TitlesTitle>
                  </>
                ) : (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {langData.hodltitlepnl}
                      {"/"}
                      {database3.totalPoint}
                      {"Pt."}
                    </TitlesTitle>
                    <div>
                      {/* Select の基本 */}
                      <SelectRaw
                        onChange={(e) => setNumPageAndMore(e)} //変更されたときに走る関数。第2引数にページ番号が入る
                        style={{
                          textAlign: "center",
                          backgroundColor: "rgba(255,255,255,0.5)",
                          perspective: "500px",
                          color: "primary.main",
                        }}
                      >
                        <MenuItem value={5} disabled={5 == numPage}>
                          5件(raw)
                        </MenuItem>
                        <MenuItem value={20} disabled={20 == numPage}>
                          20件(raw)
                        </MenuItem>
                        <MenuItem value={50} disabled={50 == numPage}>
                          50件(raw)
                        </MenuItem>
                        <MenuItem value={80} disabled={80 == numPage}>
                          80件(raw)
                        </MenuItem>
                        <MenuItem value={100} disabled={100 == numPage}>
                          100件(raw)
                        </MenuItem>
                        <MenuItem value={150} disabled={150 == numPage}>
                          150件(raw)
                        </MenuItem>
                        <MenuItem value={200} disabled={200 == numPage}>
                          200件(raw)
                        </MenuItem>
                      </SelectRaw>
                    </div>

                    {database3.imageURI.length > 0 ? (
                      <>
                        <div style={{ textAlign: "center" }}>
                          <Pagination
                            count={Math.ceil(
                              (database3.imageURI.slice(-1)[0][0] + 1) / numPage
                            )} //総ページ数
                            color="secondary" //ページネーションの色
                            shape="rounded"
                            onChange={(e, page) => setPage(page)} //変更されたときに走る関数。第2引数にページ番号が入る
                            page={page} //現在のページ番号
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    <s.SpacerLarge />
                  </>
                )}

                {database3.loading ? (
                  <>
                    <TitlesTitle
                      id="Titles"
                      style={{
                        textAlign: "center",
                        perspective: "500px",
                        color: "primary.main",
                      }}
                    >
                      {"Loading..."}
                    </TitlesTitle>
                  </>
                ) : (
                  <></>
                )}

                <Grid xs={12} sm={12}>
                  <Box
                    sx={{
                      width: 1 / 1.25,
                      height: 1 / 1,
                      mx: "auto",
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "primary.main",
                        opacity: [0.9, 0.8, 0.7],
                      },
                    }}
                  ></Box>
                </Grid>
              </Grid>
              {database3.imageURI != undefined
                ? database3.imageURI?.map((imageURI) => (
                    <>
                      {(imageURI[0] + 1) / numPage > page - 1 &&
                      (imageURI[0] + 1) / numPage <= page ? (
                        <>
                          {" "}
                          <Suspense fallback={<></>}>
                            <Grid
                              xs={6}
                              sm={2}
                              key={imageURI[0]}
                              style={{
                                perspective: "500px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: 1 / 1.25,
                                  height: 1 / 1,
                                  mx: "auto",
                                  display: "block",
                                  backgroundColor: "none",
                                  transformStyle: "preserve-3d",
                                  animation: "fadein 1s linear forwards",
                                  opacity: "0",
                                  // animationDelay: imageURI[0] + "s",
                                  "&:hover": {
                                    backgroundColor: "primary.main",
                                    opacity: [0.9, 0.8, 0.7],
                                  },
                                }}
                              >
                                <LazyAndPreloadImage
                                  alt={"logo" + imageURI[0]}
                                  src={imageURI[1]}
                                  befsrc={"/config/images/hiddenpnl.png"}
                                />

                                <StyledLink
                                  target={"_blank"}
                                  href={
                                    CONFIG.MARKETPLACE_BASEURI +
                                    CONFIG.CONTRACT_ADDRESS_PNL +
                                    "/" +
                                    imageURI[3]
                                  }
                                >
                                  <StyledTitlesName>
                                    {imageURI[2]}
                                    <BadgeBox>
                                      {imageURI[4]}
                                      {"Pt"}
                                    </BadgeBox>
                                  </StyledTitlesName>
                                </StyledLink>
                              </Box>
                            </Grid>
                          </Suspense>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ))
                : ""}
            </Grid>
          </Grid>
          <s.SpacerLarge />
        </BoxList>
        {/* PNL保有ここまで */}
      </s.Container>
    </s.Screen>
  );
}

export default App;
